.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navebar {
  color: white;
  background-color: grey;
}

.lead {
  color: black;
  outline-color: white;
}

.logo-text {
  color: silver;
}

.logo-text:hover {
  color: silver;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
  color: silver;
}

.nav-link:hover{
  color: slategray;
  text-decoration: none;
}


.landing {
  /* The image used */
  background-image: url(../images/space_music.png);

  /* Full height */
  height: 100vh;
  margin: 0vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Style */
}

.all-center {
  justify-content: center;
  align-items: center;
}

/* General purpose */
.vh-center {
  margin: 0;
  position: relative;
  top: 50vh;
  width: 100%;
  text-align: center;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

  .welcome {
    margin-top: 1rem;
    font-size: 2rem;
  }

  .lead {
    padding-bottom: 1rem;
  }

  .last-row {
    margin-bottom: 2rem;
  }
}